import React from 'react'
import { Link } from 'gatsby'
 
const NotFoundPage = () => {
    return (
        <React.Fragment>
            <div className="coming-soon-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="coming-soon-content">
                            <h2>404</h2>
                            <h3>Error - Page Not Found</h3>
                            <hr/>
                            Please check the URL.
                            <p>Otherwise, <Link to="/">click here</Link> to be redirected to the homepage</p>

                            
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NotFoundPage